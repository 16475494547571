export const ACCOUNTS = [
  {
    id: 1,
    name: 'Shared tenant',
    is_current_client: false,
  },
  {
    id: 2,
    name: 'Tenant',
    is_current_client: true,
  },
  {
    id: 3,
    name: 'Shadow public tenant',
    is_current_client: false,
  },
  {
    id: 4,
    name: "Five Sun's Eco Energy",
    is_current_client: false,
  },
  {
    id: 5,
    name: 'Laminazione Sottile',
    is_current_client: false,
  },
  {
    id: 6,
    name: 'Delfortgroup',
    is_current_client: false,
  },
  {
    id: 7,
    name: 'Sofidel',
    is_current_client: false,
  },
  {
    id: 8,
    name: 'Opéra Energie',
    is_current_client: false,
  },
  {
    id: 9,
    name: 'Eviosys',
    is_current_client: false,
  },
  {
    id: 10,
    name: 'Saverglass',
    is_current_client: false,
  },
  {
    id: 11,
    name: 'Alliance des Énergies',
    is_current_client: false,
  },
  {
    id: 12,
    name: 'Getlink',
    is_current_client: false,
  },
  {
    id: 13,
    name: 'OTC Flow',
    is_current_client: false,
  },
  {
    id: 46,
    name: 'Hirsch Gruppe',
    is_current_client: false,
  },
  {
    id: 47,
    name: 'ST',
    is_current_client: false,
  },
  {
    id: 48,
    name: 'Benali Consulting',
    is_current_client: false,
  },
  {
    id: 49,
    name: 'HHA',
    is_current_client: false,
  },
  {
    id: 50,
    name: 'Centolla',
    is_current_client: false,
  },
  {
    id: 51,
    name: 'Partenerg',
    is_current_client: false,
  },
  {
    id: 52,
    name: 'Markener',
    is_current_client: false,
  },
  {
    id: 53,
    name: 'H2V',
    is_current_client: false,
  },
  {
    id: 54,
    name: 'Aubert & Duval',
    is_current_client: false,
  },
  {
    id: 55,
    name: 'Vistra',
    is_current_client: false,
  },
  {
    id: 56,
    name: 'Sodiaal',
    is_current_client: false,
  },
  {
    id: 57,
    name: 'Mon Courtier Énergie',
    is_current_client: false,
  },
  {
    id: 58,
    name: 'Gattefossé',
    is_current_client: false,
  },
  {
    id: 59,
    name: 'Adere Paca',
    is_current_client: false,
  },
  {
    id: 60,
    name: 'Evolve Energy',
    is_current_client: false,
  },
  {
    id: 62,
    name: 'Dune Énergie',
    is_current_client: false,
  },
  {
    id: 63,
    name: 'CC Energy',
    is_current_client: false,
  },
  {
    id: 64,
    name: 'Nantes Métropole',
    is_current_client: false,
  },
  {
    id: 65,
    name: 'Harting',
    is_current_client: false,
  },
  {
    id: 66,
    name: 'Best Connect',
    is_current_client: false,
  },
  {
    id: 67,
    name: 'Primeo Energie',
    is_current_client: false,
  },
  {
    id: 68,
    name: 'Resah',
    is_current_client: false,
  },
  {
    id: 69,
    name: 'Goldman Sachs',
    is_current_client: false,
  },
];
