import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress from 'src/components/CircularProgress/CircularProgress';
import React from 'react';
import colors from 'src/styles/colors';
import { fontWeights, getRem } from 'src/styles/fonts/font';

function StatsSummary({ isLoading, data }) {
  const useStyles = makeStyles(() => ({
    scrollContainer: {
      margin: 'auto',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'grid',
      paddingRight: '16px',
    },
    indicatorLabel: {
      fontSize: getRem(14),
      fontWeight: fontWeights.SemiBold,
      color: colors.statLabel,
    },
    indicatorValue: {
      fontSize: getRem(16),
      fontWeight: fontWeights.Bold,
      marginTop: -5,
    },
    statContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dotColorContainer: { display: 'flex', alignItems: 'baseline' },
    colorBox: {
      width: 10,
      minWidth: 10,
      height: 10,
      borderRadius: '100%',
      marginRight: 7,
      display: 'flex',
    },
  }));

  const classes = useStyles();
  return (
    <Box className={classes.scrollContainer} id="scroll-container">
      <Box
        width={200}
        alignItems="center"
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        height="inherit"
      >
        <React.Fragment>
          {!isLoading ? (
            data?.map(({ label, value, dotColor }, index) => {
              return (
                <Container
                  disableGutters
                  align="center"
                  className={classes.statContainer}
                  key={`${label}-${index}`}
                >
                  <Typography className={classes.indicatorLabel}>
                    {label}
                  </Typography>
                  <div className={classes.dotColorContainer}>
                    {dotColor ? (
                      <span
                        className={classes.colorBox}
                        style={{ backgroundColor: dotColor }}
                      />
                    ) : null}
                    <Typography className={classes.indicatorValue}>
                      {value}
                    </Typography>
                  </div>
                </Container>
              );
            })
          ) : (
            <div
              style={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={20} absoluteCenter />
            </div>
          )}
        </React.Fragment>
      </Box>
    </Box>
  );
}

export default StatsSummary;
