import { ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';
import { chartTheme, chartTickPadding } from 'src/styles/theme';
import { getMatrixMaxValue } from 'src/utils/utils';
import CircularProgress from '../../CircularProgress/CircularProgress';

const ChartBar = ({ data, keys, indexBy, colors }) => {
  const maxValue = useMemo(() => getMatrixMaxValue(data, 'value'), [data]);
  const chartMaxValue = maxValue * 1.2;

  if (!data?.length) {
    return <CircularProgress size={20} />;
  }
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      height={310}
      margin={{ top: 30, right: 60, bottom: 30, left: 60 }}
      maxValue={chartMaxValue}
      padding={0.3}
      enableLabel={false}
      innerPadding={2}
      layout="vertical"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={colors}
      //   xScale={{
      //     type: `time`,
      //     format: 'native',
      //     precision: 'day',
      //     // useUTC: false,
      //   }}
      //   axisBottom={{
      //     format: (value) => {
      //       if (periodId === '5Y' || periodId === 'all') {
      //         return moment(value).format('MM/YY');
      //       }
      //       return moment(value).format('DD/MM');
      //     },
      //     // NOTE: Old code below, keep just in case
      //     type: 'time', // found here https://github.com/plouc/nivo/issues/283#issuecomment-429110419
      //     // format: periodId === '5Y' || periodId === 'all' ? `%b %y` : `%d/%m`,
      //     // tickValues: filteredData?.length || 0,
      //     // tickRotation: -90,
      //   }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      //   axisTop={null}
      //   axisRight={null}
      axisBottom={{
        tickPadding: chartTickPadding,
        // format: (date) => moment(date).format('DD/MM'),
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: 'Horizon',
        //   legendPosition: 'middle',
        //   legendOffset: 32,
      }}
      // axisLeft={{
      //   tickValues: [0, 25, 50, 75, 100],
      // }}
      // gridYValues={[0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100]}
      axisLeft={{
        tickPadding: -chartTickPadding,
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: '%',
        //   legendPosition: 'middle',
        //   legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="white"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      theme={chartTheme}
    />
  );
};

export default ChartBar;
